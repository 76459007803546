<template>
    <section class="vh-100" :style="{ backgroundImage: 'url(' + require('@/assets/img/login-screen.webp') + ')', backgroundSize: 'cover', backgroundPosition: 'top right' }">
        <div class="version-tag">{{ version }}</div>
        <div class="container-fluid" style="height: calc(100% - 73px);">
            <div class="row d-flex justify-content-center align-items-center  ">
                <div class=" ">
                    
                    <form class="p-5 form-position" style=" background: rgba(255, 255, 255, 0.8); border-radius: 15px;"  >
                        <!-- Usuario input -->
                        <div class="form-outline mb-3">
                            <label class="form-label " for="usuario-login">Usuario</label>
                            <input type="text" id="usuario-login" placeholder="Introduce usuario" v-model="usuario" class="form-control form-control-lg"/>
                        </div>

                        <!-- Contraseña input -->
                        <div class="form-outline mb-3">
                            <label class="form-label " for="contrasenya-login">Contraseña</label>
                            <input type="password" id="contrasenya-login" placeholder="Introduce contraseña" v-model="password" class="form-control form-control-lg"/>
                        </div>

                        <div class="d-flex justify-content-between align-items-center">
                            <a href="#!" class="text-body ">¿Contraseña olvidada?</a>
                        </div>

                        <div class="text-center text-lg-start mt-4 pt-2">
                            <button type="button" class="btn btn-info btn-lg"
                            style="padding-left: 2.5rem; padding-right: 2.5rem;" @click.prevent="login">Login</button>
                            <p class="small fw-bold mt-2 pt-1 mb-0 ">¿Quieres PWGS para tu negocio? <a href="#!"
                                class="link-danger "><strong><u>Solicita una demo</u></strong></a></p>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div
            class="d-flex flex-column flex-md-row text-center text-md-start justify-content-between py-4 px-4 px-xl-5 ">
            <div class="mb-3 mb-md-0">
            Copyright &copy; 2024. Todos los derechos reservados.
            </div>
        </div>
        
    </section>
</template>
<style>

.form-position {
    position: absolute;
    top: 208px; /* Ajusta este valor para mover el formulario hacia arriba o abajo */
    right: 0%;
    transform: translate(-20%, -10%);
    width: 100%;
    max-width: 500px; /* Asegúrate de que el formulario no sea demasiado ancho */
}

@media (max-width: 768px) {
    .form-position {
        top: 10%; /* Menor desplazamiento en pantallas pequeñas para evitar que se corte */
    }
}

.version-container {
    position: absolute;
    right: 30px;
    bottom: 30px;
    color: rgb(215, 211, 211); 
    font-size: 0.9rem;
}

.version-tag {
    position: absolute;
    right: 146px;  
    top: 965px; 
    border-radius: 5px;
    font-size: 12px;
    transform: rotate(9deg) skew(12deg); /* Ajusta la rotación y la deformación para alinear con la perspectiva */
    color: #cdcdcd;
    font-weight: bold;
    width: 200px;
    text-align: center;
}

</style>
<script>
import { PwgsApi } from './../../../services/PwgsApi.js'
import { ENTORNO_APUYEN } from './../../../../.env.js';
export default {
    data() {
        return {
            usuario: null,
            password: null,
            direccion: "",
            version: ''
        }
    },
    methods: {
        async login() {
            if (this.usuario.trim() === '' || this.password.length < 6)  {
                alert('Faltan datos. La contraseña debe tener mínimo 6 caracteres');
                return;
            }

            let username;  // Declarar username aquí para que esté disponible en todo el método
            let subdomain = '';

            if (this.usuario.includes('@')) {
                // Descomponer el nombre de usuario y dominio aquí
                const parts = this.usuario.split('@');
                username = parts[0];
                const domain = parts[1];
                subdomain = domain.split('.')[0];  // Extrae 'subdominio' de 'subdominio.pwgs.app'
                localStorage.setItem('subdomain', subdomain); // Guarda el subdominio para uso futuro
            } else {
                username = this.usuario;  // Usa el nombre de usuario tal como se proporcionó
                localStorage.removeItem('subdomain'); // Elimina el subdominio anterior si no se proporciona uno nuevo            
            }

            try {
                const servicio = new PwgsApi(subdomain);
                const api = new PwgsApi(subdomain);
                const datos = await servicio.login(username, this.password);
                localStorage.setItem('nombreusuario', username);
                localStorage.setItem('pwd', this.password);
                
                
                localStorage.setItem('token', datos.tokenId);
                localStorage.setItem('tramitador', username);
                const auxusuarios = await api.get('usuarios');
                const datosusuarios = auxusuarios.datos;
                for (let i = 0; i < datosusuarios.length; i++){
                    if (datosusuarios[i].nombre == username) {
                        localStorage.setItem('usuario', datosusuarios[i].idusuario);
                        this.historialurls(datosusuarios[i].idusuario);

                        this.monitorizarErrores(this.usuario);
                        this.meterurl();

                    }
                }
                this.$router.push({
                    name: 'Escritorio'
                });
            } catch (error) {
                alert(error);
                const api = new PwgsApi();
                api.get('logout'); 
                const apidos = new PwgsApi(subdomain);
                apidos.get('logout');
                localStorage.removeItem('token');
            }
        },
        async logindos() {
            if (this.usuario.trim() === '' || this.password.length < 6) {
                alert('Faltan datos. La contraseña debe tener mínimo 6 caracteres');
                return;
            }
            const servicio = new PwgsApi();
            const datos = await servicio.login(this.usuario, this.password);
            //localStorage.setItem('token', 'c29wb3J0ZTdfMzQ1NzM5');
            localStorage.setItem('token', datos.tokenId);
            this.$router.push({
                name: 'Escritorio'
            });
        
        },
        meterurl() {
            var domain = "";
            if(localStorage.getItem('subdomain')!=''){
                domain = localStorage.getItem('subdomain')+".pwgs.app";
            }else{
                let aux = ENTORNO_APUYEN.BaseURL;
                const url = new URL(aux);
                domain = url.hostname;
            }
            
            localStorage.setItem('direccion', domain);

        },
        async historialurls(idusuario) {
            const api = new PwgsApi();
            let datosusuario = await api.get('usuarios/' + idusuario);
            this.$router.push('/escritorio');
            for (let i = 0; i < datosusuario.datos.pestanas.length; i++){
                let ruta = { path: datosusuario.datos.pestanas[i].path, fullPath: datosusuario.datos.pestanas[i].fullPath, name: datosusuario.datos.pestanas[i].name }
                this.$store.dispatch('agregarVentana', ruta);

            }
            

        },
        monitorizarErrores(usuario){

            var initOpts = {
                projectKey: "rMVhQP7dYnFONci3F4h7",
                ingestPoint: "https://monitorapp.grupoapuyen.net/ingest",
                defaultInputMode: 0,
                obscureTextNumbers: false,
                obscureTextEmails: true,
            };
            var startOpts = { userID: usuario };
            (function(A,s,a,y,e,r){
                r=window.OpenReplay=[e,r,y,[s-1, e]];
                s=document.createElement('script');s.src=A;s.async=!a;
                document.getElementsByTagName('head')[0].appendChild(s);
                r.start=function(){r.push([0])};
                r.stop=function(){r.push([1])};
                r.setUserID="function(id){r.push([2,id])}";
                r.setUserAnonymousID="function(id){r.push([3,id])}";
                r.setMetadata=function(k,v){r.push([4,k,v])};
                r.event=function(k,p,i){r.push([5,k,p,i])};
                r.issue=function(k,p){r.push([6,k,p])};
                r.isActive=function(){return false};
                r.getSessionToken=function(){};
            })("//static.openreplay.com/13.0.0/openreplay-assist.js",1,0,initOpts,startOpts);
        }
    }
}
</script>
<style scoped>
.card {
    width: 50%;
    margin: 20% auto;
}
</style>